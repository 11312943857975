<div class="toolbar-wrapper">
    <div class="toolbar-filtering">
        <div class="search-wrapper">
            <ui-input
                #searchInput
                [svgIcon]="$any(!searchValue ? 'search' : undefined)"
                [expand]="true || searchValue.length === 0"
                iconPosition="right"
                [value]="searchValue"
                (valueChange)="emitSearch($event)">
            </ui-input>
            <ui-svg-icon
                (click)="clearInputValue()"
                *ngIf="searchValue"
                icon="close"
                class="reset-input"></ui-svg-icon>
        </div>
        <ng-content select="[filter]"></ng-content>
    </div>
</div>
