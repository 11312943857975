import { Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { UserActivityComponent } from './user-activity/user-activity.component';

export const routes: Routes = [
    {
        path: '',
        component: UserActivityComponent,
        canActivate: [AuthGuard]
    },
    {
        path: ':accountId',
        component: UserActivityComponent,
        canActivate: [AuthGuard]
    }
];
