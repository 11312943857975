import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { UIModule, UIThemeService } from '@bannerflow/ui';
import { UserService } from '../services/user.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, UIModule, CommonModule],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
    private userService = inject(UserService);
    loaded$ = this.userService.loaded$;

    constructor(private uiThemeService: UIThemeService) {}
}
