import { inject, Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { filter, map } from 'rxjs';

export interface IUser extends User {
    amAccountId: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
    private authService = inject(AuthService);
    loaded$ = this.authService.isLoading$.pipe(filter(isLoading => isLoading === false));
    user$ = this.authService.user$.pipe(
        filter(user => !!user),
        map(user => user as IUser)
    );
}
