<div class="filter-wrapper">
    <div class="dropdown">
        <div class="dropdown-label">User</div>
        <ui-select
            [searchable]="true"
            [multiSelect]="true"
            [useTargetWidth]="true"
            [width]="'100%'"
            [placeholder]="'All users'"
            (selectedChange)="usersFilterChange($event)"
            [selected]="filteredUsers$ | async"
            class="dropdown-select">
            @for (user of users(); track user.id) {
                <ui-option [value]="user.id"> {{ user.name }} </ui-option>
            }
        </ui-select>
    </div>
    <div class="dropdown">
        <div class="dropdown-label">Date / Time</div>
        <app-date-picker
            [selectedDate]="(filteredDateRange$ | async)!"
            (chosenDate)="dateTimeFilterChange($event)"></app-date-picker>
    </div>
    <div class="dropdown">
        <div class="dropdown-label">Brand</div>
        <ui-select
            [searchable]="true"
            [multiSelect]="true"
            [useTargetWidth]="true"
            [width]="'100%'"
            [placeholder]="'All brands'"
            [selected]="filteredBrands$ | async"
            (selectedChange)="brandsFilterChange($event)"
            class="dropdown-select">
            @for (brand of brands(); track brand.id) {
                <ui-option [value]="brand.id"> {{ brand.name }} </ui-option>
            }
        </ui-select>
    </div>
    <div class="dropdown">
        <div class="dropdown-label">Type</div>
        <ui-select
            [searchable]="true"
            [multiSelect]="true"
            [useTargetWidth]="true"
            [width]="'100%'"
            [placeholder]="'All types'"
            [selected]="filteredTypes$ | async"
            (selectedChange)="typesFilterChange($event)"
            class="dropdown-select">
            @for (type of types(); track type) {
                <ui-option [value]="type"> {{ type }} </ui-option>
            }
        </ui-select>
    </div>
    <div class="dropdown">
        <div class="dropdown-label">Action</div>
        <ui-select
            [searchable]="true"
            [multiSelect]="true"
            [useTargetWidth]="true"
            [width]="'100%'"
            [placeholder]="'All actions'"
            (selectedChange)="actionsFilterChange($event)"
            [selected]="filteredActions$ | async"
            class="dropdown-select">
            @for (action of actions(); track action) {
                <ui-option [value]="action"> {{ action }} </ui-option>
            }
        </ui-select>
    </div>
</div>
<div class="actions-wrapper">
    <div class="reset">
        <ui-svg-icon
            [icon]="'clear'"
            fill="var(--default-color-grey-dark)"></ui-svg-icon>
        <div class="reset-label">RESET ALL FILTERS</div>
    </div>
</div>
