import { CommonModule, DatePipe } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators
} from '@angular/forms';
import { UIModule, UIPopoverDirective } from '@bannerflow/ui';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { DateRange } from '../../user-activity/user-activity-models';
import { StringToDatePipe } from './stringToDate.pipe';

enum DaysRange {
    Today,
    SevenDays,
    FourteenDays
}

@Component({
    selector: 'app-date-picker',
    standalone: true,
    imports: [UIModule, CommonModule, StringToDatePipe, ReactiveFormsModule],
    providers: [DatePipe],
    templateUrl: './date-picker.component.html',
    styleUrl: './date-picker.component.scss'
})
export class DatePickerComponent implements OnDestroy, OnChanges {
    @Output() chosenDate: EventEmitter<DateRange> = new EventEmitter<DateRange>();
    @Input() selectedDate?: DateRange;
    @ViewChild('datePickerPopover') datePickerPopover!: UIPopoverDirective;
    @ViewChild('endDate') endDate!: ElementRef<HTMLInputElement>;
    @ViewChild('startDate') startDate!: ElementRef<HTMLInputElement>;
    today: Date = new Date();
    previousDateValue!: DateRange;
    daysRange = DaysRange;
    private ngUnsubscribe$ = new Subject<void>();
    readonly dateFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    datePicker = new FormGroup(
        {
            startDate: new FormControl('', [
                Validators.pattern(this.dateFormatRegex),
                Validators.required
            ]),
            endDate: new FormControl('', [
                Validators.pattern(this.dateFormatRegex),
                Validators.required
            ])
        },
        [this.dateValidator()]
    );

    constructor(private datePipe: DatePipe) {
        this.datePicker.statusChanges
            .pipe(takeUntil(this.ngUnsubscribe$), distinctUntilChanged())
            .subscribe(val => {
                if (val === 'VALID') {
                    const inputDate = {
                        startDate: this.startDateWithTime(this.datePicker.value.startDate!),
                        endDate: this.endDateWithTime(this.datePicker.value.endDate!)
                    };
                    this.chosenDate.emit(inputDate);
                }
            });
        this.datePicker.controls.startDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                if (val !== null) {
                    this.cleanDate(val, 'startDate');
                }
            });

        this.datePicker.controls.endDate.valueChanges
            .pipe(takeUntil(this.ngUnsubscribe$))
            .subscribe(val => {
                if (val !== null) {
                    this.cleanDate(val, 'endDate');
                }
            });
    }

    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
    }

    cleanDate(date: string, control: 'startDate' | 'endDate'): void {
        const regex = /\d/;
        const lastCharacter = date.slice(-1);
        let newDate = date;
        const shouldRemoveLastCharacter = !regex.test(lastCharacter);

        if (lastCharacter && shouldRemoveLastCharacter) {
            newDate = date.slice(0, -1);
        }

        if (newDate.length === 2 || newDate.length === 5) {
            newDate = `${newDate}/`;
        }

        if (lastCharacter === '/') {
            newDate = date.slice(0, -1);
        }

        this.datePicker.controls[control].setValue(newDate, { emitEvent: false });

        if (control === 'startDate' && newDate.length === 10 && this.endDate) {
            this.endDate.nativeElement.focus();
        }
        if (control === 'endDate' && newDate.length === 0 && this.startDate) {
            this.startDate.nativeElement.focus();
        }
    }

    dateValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid =
                Date.parse(control.value['startDate']) <= Date.parse(control.value['endDate']);

            return isValid ? null : { dateRangeError: true };
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedDate'].currentValue) {
            const selectedDateValue: DateRange = changes['selectedDate'].currentValue;
            if (selectedDateValue.startDate && selectedDateValue.endDate) {
                this.previousDateValue = Object.assign({}, this.selectedDate);
                this.setDateValue(selectedDateValue.startDate, selectedDateValue.endDate);
            } else {
                this.datePicker.setValue({ startDate: '', endDate: '' });
            }
        }
    }

    setDateInput(day: DaysRange): void {
        let startDate;
        switch (day) {
            case DaysRange.Today:
                startDate = this.today;
                break;
            case DaysRange.SevenDays:
                startDate = new Date(new Date().setDate(this.today.getDate() - 7));
                break;
            case DaysRange.FourteenDays:
                startDate = new Date(new Date().setDate(this.today.getDate() - 14));
                break;
            default:
                break;
        }

        this.setDateValue(startDate!, this.today);
    }

    getStartDate(date: Date): void {
        this.datePicker.controls.startDate.setValue(this.formatDate(date));
    }

    getEndDate(date: Date): void {
        this.datePicker.controls.endDate.setValue(this.formatDate(date));
    }

    applySelectedDate(): void {
        const newDate = {
            startDate: this.startDateWithTime(this.datePicker.value.startDate!),
            endDate: this.endDateWithTime(this.datePicker.value.endDate!)
        };
        this.previousDateValue = Object.assign({}, newDate);
        this.chosenDate.emit(newDate);
        this.datePickerPopover.close();
    }

    closeDatePicker(): void {
        if (!this.previousDateValue) {
            this.datePickerPopover.close();
            return;
        }

        if (this.previousDateValue !== this.selectedDate) {
            this.selectedDate = Object.assign({}, this.previousDateValue);
            this.setDateValue(this.selectedDate.startDate!, this.selectedDate.endDate!);
        }

        this.datePickerPopover.close();
    }

    clearDate(): void {
        this.datePicker.setValue({
            startDate: '',
            endDate: ''
        });
        this.chosenDate.emit(undefined);
    }

    private setDateValue(startDate: Date, endDate: Date): void {
        this.datePicker.setValue({
            startDate: this.formatDate(startDate),
            endDate: this.formatDate(endDate)
        });
    }

    private formatDate(date: Date): string {
        return this.datePipe.transform(date, 'MM/dd/YYYY') || '';
    }

    private startDateWithTime(date: string): Date {
        const startDate = new Date(new Date(date).setHours(0, 0, 0));
        return startDate;
    }

    private endDateWithTime(date: string): Date {
        const endDate = new Date(new Date(date).setHours(23, 59, 59));
        return endDate;
    }
}
