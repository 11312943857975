import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, scan, shareReplay, tap } from 'rxjs';
import { DateRange } from '../app/user-activity/user-activity-models';
import { EntityType, IActivity } from '../models/activity.types';
import { SignalRHubService } from './signalr-hub.service';

@Injectable({ providedIn: 'root' })
export class ActivityListService {
    private signalRHubService = inject(SignalRHubService);
    newActivities$ = this.signalRHubService.userActivity$.pipe(
        scan<IActivity | undefined, IActivity[]>((acc, val) => [...(val ? [val] : []), ...acc], [])
    );
    activities$ = combineLatest(this.signalRHubService.userActivityList$, this.newActivities$).pipe(
        map(([activityList, newActivities]) => {
            return {
                ...activityList,
                activities: [...newActivities, ...activityList.activities]
            };
        })
    );

    private state: IFilter = structuredClone(DEFAULT_FILTER);
    private _activeFilter$ = new BehaviorSubject<Partial<IFilter>>(structuredClone(DEFAULT_FILTER));
    activeFilter$ = this._activeFilter$.asObservable().pipe(
        map(state => {
            return { ...this.state, ...state };
        }),
        shareReplay(1),
        tap(state => (this.state = state)),
        tap(state => console.log(state))
    );

    setFilter(filter: Partial<IFilter>): void {
        this._activeFilter$.next(filter);
    }
}

export interface IFilter {
    users: IActivity['user']['id'][];
    brands: IActivity['brand']['id'][];
    types: EntityType[];
    actions: IActivity['action']['label'][];
    timestamp: DateRange | undefined;
}

export const DEFAULT_FILTER: IFilter = {
    actions: [],
    brands: [],
    types: [],
    users: [],
    timestamp: undefined
};
