import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'strToDate',
    standalone: true
})
export class StringToDatePipe implements PipeTransform {
    transform(time: string): Date | undefined {
        const newDate = new Date(time);
        return isNaN(newDate.getTime()) ? undefined : newDate;
    }
}
