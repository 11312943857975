<div
    class="date-input"
    [ngClass]="{ invalid: datePicker.invalid && datePicker.dirty }">
    <form
        [formGroup]="datePicker"
        class="input"
        #datePickerTarget="ui-popover-target"
        ui-popover-target>
        <input
            #startDate
            data-testid="startDate-input"
            [placeholder]="'MM/DD/YYYY'"
            formControlName="startDate"
            [size]="datePicker.controls.startDate.value!.length === 0 ? 10 : 8"
            [maxlength]="10" />
        <ui-svg-icon
            icon="minus-small"
            class="dash"></ui-svg-icon>
        <input
            #endDate
            data-testid="endDate-input"
            [placeholder]="'MM/DD/YYYY'"
            formControlName="endDate"
            [size]="10"
            [maxlength]="10" />
    </form>
    <div class="action-icons">
        <ui-svg-icon
            *ngIf="
                datePicker.controls.startDate.value!.length > 0 ||
                datePicker.controls.endDate.value!.length > 0
            "
            icon="clear"
            class="clear"
            fill="var(--ui-color-primary)"
            [attr.data-testid]="'clear-icon'"
            (click)="clearDate()"></ui-svg-icon>
        <ui-svg-icon
            icon="schedule"
            class="schedule"
            fill="var(--ui-color-primary)"
            [attr.data-testid]="'schedule-icon'"
            (click)="datePickerPopover.open(datePickerTarget)"></ui-svg-icon>
    </div>
</div>
<ui-popover
    #datePickerPopover="ui-popover"
    [config]="{ panelClass: 'no-padding', width: 387, offset: { x: -88, y: 5 } }">
    <ng-template ui-popover-template>
        <div class="date-select-shortcut">
            <ui-button
                text="Today"
                [attr.data-testid]="'today-btn'"
                (click)="setDateInput(daysRange.Today)">
            </ui-button>
            <ui-button
                text="Last 7 Days"
                [attr.data-testid]="'last-seven-days-btn'"
                (click)="setDateInput(daysRange.SevenDays)">
            </ui-button>
            <ui-button
                text="Last 14 Days"
                [attr.data-testid]="'last-fourteen-days-btn'"
                (click)="setDateInput(daysRange.FourteenDays)">
            </ui-button>
        </div>
        <ui-date-picker
            [outerBorders]="true"
            [dateSpanPicker]="true"
            (startDateChange)="getStartDate($event)"
            (endDateChange)="getEndDate($event)"
            [maxDate]="today"
            [startDate]="datePicker.value.startDate! | strToDate"
            [endDate]="datePicker.value.endDate! | strToDate"></ui-date-picker>
        <div class="action-buttons">
            <ui-button
                [attr.data-testid]="'cancel-btn'"
                text="Cancel"
                (click)="closeDatePicker()">
            </ui-button>
            <ui-button
                id="interaction-filter-set-date"
                [attr.data-testid]="'set-date'"
                text="Apply"
                type="primary"
                (click)="applySelectedDate()">
            </ui-button>
        </div>
    </ng-template>
</ui-popover>
