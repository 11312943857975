import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UIInputComponent, UIModule } from '@bannerflow/ui';

@Component({
    selector: 'app-table-toolbar',
    standalone: true,
    imports: [UIModule, CommonModule],
    templateUrl: './table-toolbar.component.html',
    styleUrl: './table-toolbar.component.scss'
})
export class TableToolbarComponent {
    @Input() searchValue!: string;
    @Output() searchChange = new EventEmitter<string>();
    @ViewChild('searchInput') searchInput!: UIInputComponent;

    emitSearch(value: string): void {
        this.searchChange.emit(value);
    }

    clearInputValue(): void {
        this.searchValue = '';
        this.emitSearch(this.searchValue);
        this.searchInput.focus();
    }
}
