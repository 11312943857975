import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { UIModule, UITableDataSource } from '@bannerflow/ui';
import { debounceTime, map, Observable, skip, Subject } from 'rxjs';
import { IActivity, IActivityListData } from '../../models/activity.types';
import { ActivityListService, DEFAULT_FILTER, IFilter } from '../../services/activitylist.service';
import { SignalRHubService } from '../../services/signalr-hub.service';
import { TableToolbarComponent } from '../table-toolbar/table-toolbar.component';
import { UserActivityFilterComponent } from '../user-activity-filter/user-activity-filter.component';
import { ActiveColumnDef } from './user-activity-models';

@Component({
    selector: 'app-user-activity',
    standalone: true,
    imports: [UIModule, CommonModule, TableToolbarComponent, UserActivityFilterComponent],
    templateUrl: './user-activity.component.html',
    styleUrl: './user-activity.component.scss'
})
export class UserActivityComponent implements OnInit {
    private activatedRoute = inject(ActivatedRoute);
    private signalRHub = inject(SignalRHubService);
    private router = inject(Router);
    private activityListService = inject(ActivityListService);
    private destroyRef = inject(DestroyRef);

    readonly searchValue$!: Observable<string>;
    private readonly nameFilter$ = new Subject<string>();
    readonly dataSource = new UITableDataSource<IActivityListData>();
    activePage$!: Observable<number>;
    activePageSizeOptions$!: Observable<number>;
    pageSizeOptions: number[] = [25, 50, 150];

    columnNames: ActiveColumnDef[] = [
        {
            name: 'Activity',
            columnDef: 'activity',
            active: true,
            isCustom: true
        },
        {
            name: 'Brand',
            columnDef: 'brand',
            active: true,
            isCustom: true
        },
        {
            name: 'Date / Time',
            columnDef: 'timestamp',
            active: true,
            isCustom: true
        },
        {
            name: 'User',
            columnDef: 'user',
            active: true,
            isCustom: true
        }
    ];

    private searchValue = '';
    totalItems = 0;
    private data: IActivityListData[] = [];
    private filter: IFilter = DEFAULT_FILTER;

    loading = true;

    constructor() {
        document.title = 'Bannerflow - User Activity';
        this.searchValue$ = this.activatedRoute.queryParams.pipe(map(params => params['search'] || ''));
        this.signalRHub.init(
            this.activatedRoute.snapshot.params['accountId'],
            Number(this.activatedRoute.snapshot.queryParams['pageSize']),
            Number(this.activatedRoute.snapshot.queryParams['page'])
        );
    }

    ngOnInit() {
        this.activityListService.activities$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(data => {
                const restructuredData = this.toCollapsedCorrelationData(data.activities);
                this.dataSource.setData(restructuredData);
                this.data = restructuredData;
                this.totalItems = data.totalItemsCount!;
                this.filterDataSource();
            });

        this.searchValue$.pipe(skip(1), takeUntilDestroyed(this.destroyRef)).subscribe(val => {
            this.searchValue = val;
            this.filterDataSource();
        });

        this.activityListService.activeFilter$
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(filter => {
                this.filter = filter;
                this.filterDataSource();
            });

        this.nameFilter$.pipe(debounceTime(400), takeUntilDestroyed(this.destroyRef)).subscribe(val => {
            this.router.navigate([], {
                queryParams: { search: val.length > 0 ? val : undefined },
                queryParamsHandling: 'merge'
            });
        });

        this.activePage$ = this.activatedRoute.queryParams.pipe(
            map(params => {
                const page = Number(params['page'] || '1');
                if (!params['page']) {
                    this.updateQueryParams({ page });
                }
                return page;
            })
        );

        this.activePageSizeOptions$ = this.activatedRoute.queryParams.pipe(
            map(params => {
                const pageSize = Number(params['pageSize'] || this.pageSizeOptions[0]);
                if (!params['pageSize']) {
                    this.updateQueryParams({ pageSize });
                }
                return pageSize;
            })
        );
    }

    filterDataSource() {
        const startDate = this.filter.timestamp
            ? Date.parse(this.filter.timestamp.startDate.toISOString())
            : undefined;
        const endDate = this.filter.timestamp
            ? Date.parse(this.filter.timestamp.endDate.toISOString())
            : undefined;
        const searchValue = this.searchValue;

        const filteredData = this.data.filter(activity => {
            const isWithinDate =
                startDate && endDate
                    ? Date.parse(activity.timestamp) >= startDate &&
                      Date.parse(activity.timestamp) <= endDate
                    : true;

            const matchingUsers = isSuperset([activity.user.id], this.filter.users);
            const matchingActions = isSuperset([activity.action.label], this.filter.actions);
            const matchingBrands = isSuperset([activity.brand.id], this.filter.brands);
            const matchingTypes = isSuperset([activity.entity.type], this.filter.types);

            const filterIsMatching =
                matchingUsers && matchingActions && matchingBrands && matchingTypes && isWithinDate;

            return (
                filterIsMatching &&
                (activity.user.name!.toLowerCase().includes(searchValue.toLowerCase()) ||
                    activity.action.label.toLowerCase().includes(searchValue.toLowerCase()) ||
                    activity.entity.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                    activity.entity.type.toLowerCase().includes(searchValue.toLowerCase()) ||
                    activity.brand.name!.toLowerCase().includes(searchValue.toLowerCase()))
            );
        });

        this.dataSource.setData(filteredData);
    }

    searching(inputValue: string) {
        this.nameFilter$.next(inputValue);
    }

    pageChanged(page: number): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { page },
            queryParamsHandling: 'merge'
        });
    }

    pageSizeChanged(pageSize: number): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { pageSize, page: 1 },
            queryParamsHandling: 'merge'
        });
    }

    private updateQueryParams(params: Record<string, number>) {
        this.router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge'
        });
    }

    private toCollapsedCorrelationData(activites: IActivity[]): IActivityListData[] {
        const correlatedActivites = new Set<string>();
        const newData: IActivityListData[] = [];
        for (const activity of activites) {
            if (!activity.correlationId) {
                newData.push(activity);
                continue;
            }

            const correlation = correlatedActivites.has(activity.correlationId);
            if (correlation) {
                continue;
            }

            const correlatedActivities = activites.filter(
                a => a.correlationId === activity.correlationId && activity !== a
            );

            if (correlatedActivities.length === 0) {
                newData.push(activity);
                continue;
            }

            const entity = activity.entity;

            newData.push({
                ...activity,
                correlationName: `${correlatedActivities.length + 1} ${entity.type}s`,
                correlations: correlatedActivities
            });

            correlatedActivites.add(activity.correlationId);
        }

        console.log(newData);

        return newData;
    }

    private expandAcitivityRow(clickedActivity: IActivityListData): void {
        const restructuredData: IActivityListData[] = [];

        for (const activity of this.data) {
            restructuredData.push(activity);

            if (activity === clickedActivity && activity.correlations) {
                activity.expanded = true;
                restructuredData.push(...activity.correlations);
            }
        }

        this.dataSource.setData(restructuredData);
        this.data = restructuredData;
    }

    private collapseAcitivityRow(clickedActivity: IActivityListData): void {
        const restructuredData: IActivityListData[] = [];

        for (const activity of this.data) {
            if (clickedActivity === activity) {
                activity.expanded = false;
            }

            if (
                clickedActivity !== activity &&
                activity.correlationId === clickedActivity.correlationId
            ) {
                continue;
            }

            restructuredData.push(activity);
        }

        this.dataSource.setData(restructuredData);
        this.data = restructuredData;
    }

    onRowClicked({ row: activity }: { mouseEvent: MouseEvent; row: IActivityListData }): void {
        if (!activity.correlationId) {
            return;
        }

        if (!activity.expanded) {
            this.expandAcitivityRow(activity);
        } else {
            this.collapseAcitivityRow(activity);
        }
    }
}

function isSuperset<T>(a: T[], b: T[]): boolean {
    return new Set(a).isSupersetOf(new Set(b));
}
