<h1>Change log</h1>
<div>
    <app-table-toolbar
        #filterTarget="ui-popover-target"
        ui-popover-target
        [searchValue]="(searchValue$ | async) || ''"
        (searchChange)="searching($event)">
        <ng-container filter>
            <ui-button
                type="default"
                svgIcon="settings-filter"
                (click)="filterPopover.open(filterTarget)"></ui-button>
        </ng-container>
    </app-table-toolbar>
    <ui-table
        [columnNames]="columnNames"
        [dataSource]="dataSource"
        (rowClicked)="onRowClicked($event)">
        <ng-container cdkColumnDef="activity">
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                [ngStyle]="{ width: '50%' }"
                class="cell-header text">
                <div class="cell-header-wrapper">Activity</div>
            </th>
            <td
                cdk-cell
                *cdkCellDef="let row; let i = index"
                class="cell text">
                <div class="row-wrapper">
                    @if (row.correlations) {
                        <ui-svg-icon
                            style="transform: translateY(2px); margin-right: 4px"
                            [icon]="row.expanded ? 'arrow-down' : 'arrow-right'"></ui-svg-icon
                        ><b>{{ row.correlationName }}</b
                        >&nbsp;were&nbsp;<b>{{ row.action.label }}</b>
                    } @else {
                        <b>{{ row.entity.type }}</b
                        >&nbsp;<b>{{ row.entity.name }}</b
                        >&nbsp;was&nbsp;<b>{{ row.action.label }}</b>
                    }
                </div>
            </td>
        </ng-container>
        <ng-container cdkColumnDef="brand">
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                [ngStyle]="{ width: '15%' }"
                class="cell-header text">
                <div class="cell-header-wrapper">Brand</div>
            </th>
            <td
                cdk-cell
                *cdkCellDef="let row; let i = index"
                class="cell text">
                <div class="row-wrapper">
                    {{ row.brand.name }}
                </div>
            </td>
        </ng-container>
        <ng-container cdkColumnDef="timestamp">
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                [ngStyle]="{ width: '15%' }"
                class="cell-header text">
                <div class="cell-header-wrapper">Date / Time</div>
            </th>
            <td
                cdk-cell
                *cdkCellDef="let row; let i = index"
                class="cell text">
                <div class="row-wrapper">
                    {{ row.timestamp | date: "dd/MM/yyyy HH:mm" }}
                </div>
            </td>
        </ng-container>
        <ng-container cdkColumnDef="user">
            <th
                cdk-header-cell
                *cdkHeaderCellDef
                [ngStyle]="{ width: '20%' }"
                class="cell-header text">
                <div class="cell-header-wrapper">User</div>
            </th>
            <td
                cdk-cell
                *cdkCellDef="let row; let i = index"
                class="cell text">
                <div class="row-wrapper">
                    {{ row.user.name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a
                        href="{{ row.entity.link }}"
                        target="_blank">
                        <ui-svg-icon icon="go-to-url-large"></ui-svg-icon
                    ></a>
                </div>
            </td>
        </ng-container>
    </ui-table>
    <div class="paginator">
        <ui-paginator
            [totalItems]="totalItems"
            [pageSizeOptions]="pageSizeOptions"
            [textDescription]="'items out of' + ' ' + totalItems"
            [activePage]="(activePage$ | async) ?? 0"
            [activePageSizeOption]="(activePageSizeOptions$ | async) ?? 0"
            (pageChange)="pageChanged($event)"
            (numOfItemsToDisplay)="pageSizeChanged($event)"></ui-paginator>
    </div>
</div>

<ui-popover
    #filterPopover="ui-popover"
    [config]="{
        maxWidth: 700,
        minWidth: 700,
        positions: [
            {
                originX: 'end',
                originY: 'bottom',
                overlayX: 'end',
                overlayY: 'top',
                offsetY: 10
            }
        ]
    }">
    <ng-template ui-popover-template>
        <app-user-activity-filter (closeFilter)="filterPopover.close()"></app-user-activity-filter>
    </ng-template>
</ui-popover>
