import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimations(),
        provideRouter(routes),
        provideHttpClient(withFetch()),
        provideAuth0({
            domain: environment.loginUrl,
            clientId: environment.clientId,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: 'https://bannerflow.com/resources/',
                scope: 'openid profile email offline_access user-activity-service'
            },
            httpInterceptor: {
                allowedList: Object.values(environment.origins).map(url => `${url}/*`)
            },
            useRefreshTokens: true
        })
    ]
};
