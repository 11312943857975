import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UIModule } from '@bannerflow/ui';
import { map } from 'rxjs';
import { ActivityListService, IFilter } from '../../services/activitylist.service';
import { DateRange } from '../user-activity/user-activity-models';
import { DatePickerComponent } from './date-picker/date-picker.component';

@Component({
    selector: 'app-user-activity-filter',
    standalone: true,
    imports: [UIModule, CommonModule, DatePickerComponent],
    templateUrl: './user-activity-filter.component.html',
    styleUrl: './user-activity-filter.component.scss'
})
export class UserActivityFilterComponent {
    @Output() closeFilter: EventEmitter<void> = new EventEmitter<void>();
    private activityListService = inject(ActivityListService);

    filteredUsers$ = this.activityListService.activeFilter$.pipe(map(({ users }) => users));
    filteredBrands$ = this.activityListService.activeFilter$.pipe(map(({ brands }) => brands));
    filteredTypes$ = this.activityListService.activeFilter$.pipe(map(({ types }) => types));
    filteredActions$ = this.activityListService.activeFilter$.pipe(map(({ actions }) => actions));
    filteredDateRange$ = this.activityListService.activeFilter$.pipe(map(({ timestamp }) => timestamp));

    users = toSignal(
        this.activityListService.activities$.pipe(
            map(activities => distinct(activities.activities.map(({ user }) => user)))
        )
    );
    brands = toSignal(
        this.activityListService.activities$.pipe(
            map(activities => distinct(activities.activities.map(({ brand }) => brand)))
        )
    );
    types = toSignal(
        this.activityListService.activities$.pipe(
            map(activities => distinct(activities.activities.map(({ entity }) => entity.type)))
        )
    );
    actions = toSignal(
        this.activityListService.activities$.pipe(
            map(activities => distinct(activities.activities.map(({ action }) => action.label)))
        )
    );

    usersFilterChange(users: IFilter['users']): void {
        this.activityListService.setFilter({ users: users });
    }

    brandsFilterChange(brands: IFilter['brands']): void {
        this.activityListService.setFilter({ brands });
    }

    typesFilterChange(types: IFilter['types']): void {
        this.activityListService.setFilter({ types });
    }

    actionsFilterChange(actions: IFilter['actions']): void {
        this.activityListService.setFilter({ actions });
    }

    dateTimeFilterChange(timestamp: DateRange): void {
        this.activityListService.setFilter({ timestamp });
    }

    resetFilter(): void {
        this.activityListService.setFilter({
            actions: [],
            brands: [],
            types: [],
            users: [],
            timestamp: undefined
        });
    }
}

function distinct<T extends object | string | number>(arr: T[]): T[] {
    return Array.from(new Set(arr));
}
