import { inject, Injectable } from '@angular/core';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';
import { IActivity, IActivityList, UserActivityHubServerToClientEvent } from '../models/activity.types';
import { UserService } from './user.service';
import { isActivity, isActivityList } from './utils';

@Injectable({ providedIn: 'root' })
export class SignalRHubService {
    private userService = inject(UserService);
    private hubRoute = `${environment.origins.userActivityService}/user-activity/hub`;
    private connection = new HubConnectionBuilder()
        .withUrl(this.hubRoute, { withCredentials: false })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    private _userActivityList$ = new BehaviorSubject<IActivityList>({ activities: [] });
    userActivityList$ = this._userActivityList$.asObservable();
    private _userActivity$ = new BehaviorSubject<IActivity | undefined>(undefined);
    userActivity$ = this._userActivity$.asObservable();

    /** Monkey patch types */
    private on = this.connection.on.bind(this.connection) as (
        eventName: keyof UserActivityHubServerToClientEvent,
        callback: (...args: unknown[]) => void
    ) => void;

    async init(accountId?: string, pageSize?: number, pageNumber?: number): Promise<void> {
        console.log(typeof pageSize);
        try {
            const user = await firstValueFrom(this.userService.user$);
            await this.connection.start();
            console.log(user);

            this.initSubscribers();

            await this.connection.invoke('SubscribeToAccountActivity', {
                AccountId: accountId ?? user.amAccountId
            });

            await this.connection.invoke('GetPaginatedActivities', {
                AccountId: accountId ?? user.amAccountId,
                Pagination: {
                    PageNumber: pageNumber,
                    PageSize: pageSize
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    private initSubscribers(): void {
        this.connection.onclose(async () => {
            console.log('Connection was closed.');
        });

        this.on('UserActivityList', data => {
            if (isActivityList(data)) {
                // const withFakeCorrelationData: IActivityList = {
                //     activities: [
                //         ...data.activities,
                //         {
                //             user: {
                //                 id: 'dc568565-7c6d-4f4e-b3a2-0c9d961e78d3',
                //                 name: 'Someone Naughty'
                //             },
                //             action: { label: ActionLabel.Deleted },
                //             entity: {
                //                 id: '234324',
                //                 name: 'My size',
                //                 sizeId: '',
                //                 type: EntityType.Size,
                //                 link: 'https://sandbox-studio.bannerflow.com/brand/04462494-e8ac-46a6-8968-211fda94ecf8/creativeset/234324'
                //             },
                //             brand: {
                //                 id: '04462494-e8ac-46a6-8968-211fda94ecf8',
                //                 name: 'My small brand'
                //             },
                //             timestamp: '2024-09-27T09:05:24.786278+00:00',
                //             correlationId: '1'
                //         },
                //         {
                //             user: {
                //                 id: 'dc568565-7c6d-4f4e-b3a2-0c9d961e78d3',
                //                 name: 'Someone Naughty'
                //             },
                //             action: { label: ActionLabel.Deleted },
                //             entity: {
                //                 id: '234324',
                //                 name: 'My size',
                //                 sizeId: '',
                //                 type: EntityType.Size,
                //                 link: 'https://sandbox-studio.bannerflow.com/brand/04462494-e8ac-46a6-8968-211fda94ecf8/creativeset/234324'
                //             },
                //             brand: {
                //                 id: '04462494-e8ac-46a6-8968-211fda94ecf8',
                //                 name: 'My small brand'
                //             },
                //             timestamp: '2024-09-27T09:05:24.786278+00:00',
                //             correlationId: '1'
                //         },
                //         {
                //             user: {
                //                 id: 'dc568565-7c6d-4f4e-b3a2-0c9d961e78d3',
                //                 name: 'Someone Naughty'
                //             },
                //             action: { label: ActionLabel.Deleted },
                //             entity: {
                //                 id: '234324',
                //                 name: 'My size',
                //                 sizeId: '',
                //                 type: EntityType.Size,
                //                 link: 'https://sandbox-studio.bannerflow.com/brand/04462494-e8ac-46a6-8968-211fda94ecf8/creativeset/234324'
                //             },
                //             brand: {
                //                 id: '04462494-e8ac-46a6-8968-211fda94ecf8',
                //                 name: 'My small brand'
                //             },
                //             timestamp: '2024-09-27T09:05:24.786278+00:00',
                //             correlationId: '1'
                //         },
                //         {
                //             user: {
                //                 id: 'dc568565-7c6d-4f4e-b3a2-0c9d961e78d3',
                //                 name: 'Someone Naughty'
                //             },
                //             action: { label: ActionLabel.Deleted },
                //             entity: {
                //                 id: '234324',
                //                 name: 'My size',
                //                 sizeId: '',
                //                 type: EntityType.Size,
                //                 link: 'https://sandbox-studio.bannerflow.com/brand/04462494-e8ac-46a6-8968-211fda94ecf8/creativeset/234324'
                //             },
                //             brand: {
                //                 id: '04462494-e8ac-46a6-8968-211fda94ecf8',
                //                 name: 'My small brand'
                //             },
                //             timestamp: '2024-09-27T09:05:24.786278+00:00',
                //             correlationId: '2'
                //         },
                //         {
                //             user: {
                //                 id: 'dc568565-7c6d-4f4e-b3a2-0c9d961e78d3',
                //                 name: 'Someone Naughty'
                //             },
                //             action: { label: ActionLabel.Deleted },
                //             entity: {
                //                 id: '234324',
                //                 name: 'My size',
                //                 sizeId: '',
                //                 type: EntityType.Size,
                //                 link: 'https://sandbox-studio.bannerflow.com/brand/04462494-e8ac-46a6-8968-211fda94ecf8/creativeset/234324'
                //             },
                //             brand: {
                //                 id: '04462494-e8ac-46a6-8968-211fda94ecf8',
                //                 name: 'My small brand'
                //             },
                //             timestamp: '2024-09-27T09:05:24.786278+00:00',
                //             correlationId: '2'
                //         }
                //     ]
                // };
                this._userActivityList$.next(data);
            }
        });

        this.on('UserActivity', data => {
            if (isActivity(data)) {
                this._userActivity$.next(data);
            }
        });
    }
}
